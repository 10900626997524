'use client'

import { FC, useEffect, useState } from 'react'

import { WagmiProvider } from 'wagmi'

import { TorexRegistry } from '@/types'
import { config, projectId } from '@/wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from '../theme-provider'
import { TorexRegistryProvider } from './TorexRegistryProvider'

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { PricesProvider } from './PricesProvider'

const queryClient = new QueryClient()

type ProviderProps = {
  children: React.ReactNode
  serverProps: {
    torexRegistry: TorexRegistry
  }
}

if (!projectId) throw new Error('Project ID is not defined')

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  allowUnsupportedChain: true,
  privacyPolicyUrl: '/privacy',
  termsConditionsUrl: '/terms'
})

export const Providers: FC<ProviderProps> = ({ children, serverProps: { torexRegistry } }) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  return (
    <TorexRegistryProvider torexRegistry={torexRegistry}>
      <ThemeProvider attribute='class' defaultTheme='dark' disableTransitionOnChange>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <PricesProvider>{mounted && children}</PricesProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </ThemeProvider>
    </TorexRegistryProvider>
  )
}
