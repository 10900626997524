import shortenHex, { cn } from '@/utils'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { forwardRef } from 'react'
import { useAccount } from 'wagmi'
import { Button, ButtonProps, buttonVariants } from './ui/button'
import { Icons } from './ui/icons'

export const ConnectButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, children, ...props }, _ref) => {
    const { address: user } = useAccount()
    const { open } = useWeb3Modal()

    return (
      <Button
        data-testid='connect-wallet'
        {...props}
        onClick={evt => {
          props.onClick?.(evt)
          open()
        }}
        className={cn(buttonVariants({ variant, size, className }), 'flex gap-1')}
      >
        <p>{user ? shortenHex(user) : children}</p>
        {user && <Icons.chevronDown size={16} />}
      </Button>
    )
  }
)
